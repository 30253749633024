import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

// import custom validator to validate that password and confirm password fields match
import { MustMatch } from './../../core/_helpers/must-match.validator';

import { SlimLoadingBarService } from 'ng2-slim-loading-bar';
import { AlertService, AuthenticationService } from './../../core/_services';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {

  forgetForm: FormGroup;
  forgetFormEnableStatus: boolean = true;
  EyeFlag: boolean = false;
  loading = false;
  submitted = false;
  //data = [];
  forgetResetForm: FormGroup;
  forgetResetFormEnableStatus: boolean = false;

  seQuestionList : any = {};

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private _loadingBar: SlimLoadingBarService,
    private alertService: AlertService,
    private toasterservice : ToastrService
  ) {

    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }

    this.createForgetPaswdForm();
    this.createForgetResetPaswdForm();

  }

  createForgetPaswdForm() {
    this.forgetForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  // convenience getter for easy access to form fields
  // get f() {
  //   return this.forgetForm.controls;
  // }

  createForgetResetPaswdForm() {
    this.forgetResetForm = this.fb.group({
     // email: ['', [Validators.required, Validators.email]],
     newPassword: [
      '',
      [
        Validators.required,
        Validators.pattern("^(?=.*?[a-z])(?=.*?[0-9]).{8,12}$")
       ]
    ],
    //  newPassword: ['', [Validators.required, Validators.minLength(6)]],
      // confirmPassword: ['', Validators.required],
      otp: ['', Validators.required]
    });
  }

  get s() {
    return this.forgetResetForm.controls;
  }

  get f() {
    return this.forgetForm.controls;
  }


  onSubmitForgetForm() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.forgetForm.invalid) {
      return;
    }


    this._loadingBar.start();
    this.loading = true;
    this.authenticationService.forgetPassword(this.forgetForm.value).pipe(first()).subscribe(data => {
      if(data.status == 'success'){
        this.toasterservice.success("OTP Email Sent to reset password", 'Success');
        this._loadingBar.complete();
        this.forgetFormEnableStatus = false;
        this.forgetResetFormEnableStatus = true;
      }else{
        this.toasterservice.error(data.message, 'Error');
        this._loadingBar.stop();
        this.loading = false;
      }
    
      //this.router.navigate(['/login']);
    },
      error => {
        this.toasterservice.error(error, 'Error');
        this._loadingBar.stop();
        this.loading = false;
      });
  }

  onClick() {
    this.EyeFlag = !this.EyeFlag;
  }


  onSubmitForgetRestForm() {
    console.log('click',this.forgetResetForm.value.newPassword);
    this.submitted = true;
    // stop here if form is invalid
    if (this.forgetResetForm.invalid) {
      return;
    }

    let obj = {
      "email":this.forgetForm.value.email,
      "newPassword":this.forgetResetForm.value.newPassword,
      "otp":this.forgetResetForm.value.otp
    }

    this._loadingBar.start();
    this.loading = true;
    this.authenticationService.forgetPasswordReset(obj).pipe(first()).subscribe(data => {
      if(data.status == 'success'){
        this._loadingBar.complete();
        this.forgetFormEnableStatus = true;
        this.forgetResetFormEnableStatus = false;
        this.router.navigate(['/login']);
        this.toasterservice.success('Password Changed Successfully. Login with the updated password', "Success");

      }else{
        this.toasterservice.error(data.message, "Error");
        this._loadingBar.stop();
        this.loading = false;
      }
      
    },
      error => {
        this.toasterservice.error(error,"Error");
        this._loadingBar.stop();
        this.loading = false;
      });
  }


//   getSecurityQuestion() {
//     console.log(this.f.questionGroup);
//     const obj = {
//         email: this.f.email.value,
//     };
//     this.authenticationService.getSecurityQuestion(obj).subscribe((data: any) => {
//         console.log(data);
//         if (data.status === 'success') {
//             this.seQuestionList = data['contacts'];
//         }
//     });
// }

  ngOnInit(): void {
  }

}
