import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

import { User } from './../../modules/admin/models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;
    usrToken: string;

    ENVIRONMENT_URL = environment.apiUrl;

    constructor(private http: HttpClient, private router: Router) {
        this.currentUserSubject = new BehaviorSubject<any>(
            localStorage.getItem('currentUser')
        );
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue() {
        return this.currentUserSubject.value;
      }


    login(email, password) {
        const Login= {
            email,
            password
        }
        return this.http
        .post(`${this.ENVIRONMENT_URL}/admin/auth/authentication`,Login)
            .pipe(map((data: any) => {
                if (data.status === 'success') {
                    localStorage.setItem('email', email);    
                }
                return data;
            }));
    }


    register(user: User) {
        return this.http.post(`${this.ENVIRONMENT_URL}/auth/register`, user);
    }


    // Libraries function for Authorization and authentication --------------------------------------------
    verifyOTP(obj) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/verifyOtp`, obj).pipe(
          map((data: any) => {
          //  console.log(data);
            if (data.status === 'success') {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              localStorage.setItem('token', data.token);
           //   console.log(localStorage.getItem('token'));
              localStorage.setItem('currentUser', data.user);
              localStorage.setItem('email', data.user.email);
              this.currentUserSubject.next(data);
            }
            return data;
          })
        );
      }



    forgetPassword(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/forgetPassword`, data).pipe(map((data:any) => {
            return data;
        }));

    }

    forgetPasswordReset(data) {
        return this.http.post(`${this.ENVIRONMENT_URL}/admin/forgetPassword_reset`, data).pipe(map((data:any) => {
            return data;
        }));

    }

  

    getSecurityQuestion(data) {
        return this.http.post(`api/users/getSecurityQuestion`, data);
    }
    
  

    //--------------------------------------------------------------------------------------

    logout() {
        //fake-end authenticate logout:-------------
        localStorage.removeItem('userToken');
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        //--------------------------------------------
    }
}