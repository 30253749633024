<div class="login-box">
    <div class="login-logo">
        <img class="" src="assets/img/logo-new.png" alt="" style="width: 147px;height: 50px;"/> <b> | ADMIN </b>
    </div>

    <!-- /.login-logo -->
    <div class="card">
        <div class="card-body login-card-body">
            <app-auth-alert></app-auth-alert>
            <p class="login-box-msg">Sign in to start your session</p>

            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="form-group">

                    <div class="input-group">
                        <input type="text" formControlName="email" class="form-control" #email 
                        [ngClass]="{ 'is-invalid': submitted && f.email.errors || f.email.invalid && (f.email.dirty || f.email.touched) }"/>
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-envelope"></span>
                            </div>
                        </div>
                        <div *ngIf="submitted && f.email.errors || f.email.invalid && (f.email.dirty || f.email.touched)" class="error invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="input-group">
                        <!-- [ngClass]="{ 'is-invalid': submitted && f.firstName.errors || f.firstName.invalid && (f.firstName.dirty || f.firstName.touched)}"  -->
                        <input [type]="fieldTextType ?'text':'password'" formControlName="password"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.password.errors || f.password.invalid && (f.password.dirty || f.password.touched) }" />
                     
                        <div class="input-group-append">
                            <span class="input-group-text">
                                <i class="fa" [ngClass]="{
                                      'fa-eye-slash': !fieldTextType,
                                      'fa-eye': fieldTextType
                                    }" (click)="toggleFieldTextType()"></i>
                            </span>
                        </div>
                        <div *ngIf="submitted && f.password.errors || f.password.invalid && (f.password.dirty || f.password.touched)" class="error invalid-feedback">
                            <div *ngIf="f.password.errors.required">Password is required</div>
                        </div>
                    </div>
                   
                </div>

                 <!-- <div class="input-group">
                        <input type="password" formControlName="password" class="form-control" />
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                        
                    </div>
                    <div *ngIf="f.password.invalid && (f.password.dirty || f.password.touched)" class="text text-danger">
                        <div *ngIf="f.password.errors.required">Password is required</div>
                    </div> -->

                <div class="row">
                    <div class="col-8">

                    </div>
                    <!-- /.col -->
                    <div class="col-4">
                        <button type="submit" class="btn btn-primary btn-block">Login</button>
                    </div>
                    <!-- /.col -->
                </div>
            </form>
            <!-- /.social-auth-links -->
            <p class="mb-1">
                <a href="javascript:void(0);" [routerLink]="['/auth/forget-password']" >I forgot my password</a>
            </p>
            <p class="mb-0">
                <!-- <a routerLink="/auth/register" class="text-center">Create an account</a> -->

            </p>
        </div>
        <!-- /.login-card-body -->
    </div>
</div>